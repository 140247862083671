<template>
  <div>
    <div class="logo-horizontal">
      <router-link to="/"
        ><img src="@/assets/horizontal.png" alt=""
      /></router-link>
    </div>
    <div class="container">
      <h1>Jelszóváltoztatás</h1>
      <div v-if="!done">
        <div v-if="!$route.params.id">
          <h2 class="label">Régi jelszó</h2>
          <div class="flexi">
            <b-form-input
              :state="oldPasswordState"
              :type="oldPasswordType"
              v-model="oldPassword"
              maxlength="30"
              placeholder="Adj meg egy jelszót..."
              class="password-input"
            ></b-form-input>
            <div
              v-if="oldPasswordType == 'password'"
              @click="oldPasswordTypeShow(true)"
              class="eye"
              v-b-tooltip.hover
              title="Megmutat"
            >
              <b-icon icon="eye"></b-icon>
            </div>
            <div
              v-else
              @click="oldPasswordTypeShow(false)"
              class="eye"
              v-b-tooltip.hover
              title="Elrejt"
            >
              <b-icon icon="eye-slash"></b-icon>
            </div>
            <b-form-invalid-feedback id="input-live-feedback">
              A jelszó minimum 8, maximum 30 karakter hosszú lehet és nem
              tartalmazhat speciális karaktereket!
            </b-form-invalid-feedback>
          </div>
        </div>

        <div>
          <h2 class="label">Jelszó</h2>
          <div class="flexi">
            <b-form-input
              :state="passwordState"
              :type="passwordType"
              v-model="password"
              maxlength="30"
              placeholder="Adj meg egy jelszót..."
              class="password-input"
            ></b-form-input>
            <div
              v-if="passwordType == 'password'"
              @click="passwordTypeShow(true)"
              class="eye"
              v-b-tooltip.hover
              title="Megmutat"
            >
              <b-icon icon="eye"></b-icon>
            </div>
            <div
              v-else
              @click="passwordTypeShow(false)"
              class="eye"
              v-b-tooltip.hover
              title="Elrejt"
            >
              <b-icon icon="eye-slash"></b-icon>
            </div>
            <b-form-invalid-feedback id="input-live-feedback">
              A jelszó minimum 8, maximum 30 karakter hosszú lehet és nem
              tartalmazhat speciális karaktereket!
            </b-form-invalid-feedback>
          </div>
        </div>

        <div>
          <h2 class="label">Jelszó újra</h2>
          <div class="flexi">
            <b-form-input
              :state="password2State"
              :type="password2Type"
              v-model="password2"
              maxlength="30"
              placeholder="Add meg újra a jelszót..."
              class="password-input"
            ></b-form-input>
            <div
              v-if="password2Type == 'password'"
              @click="password2TypeShow(true)"
              class="eye"
              v-b-tooltip.hover
              title="Megmutat"
            >
              <b-icon icon="eye"></b-icon>
            </div>
            <div
              v-else
              @click="password2TypeShow(false)"
              class="eye"
              v-b-tooltip.hover
              title="Elrejt"
            >
              <b-icon icon="eye-slash"></b-icon>
            </div>
            <b-form-invalid-feedback id="input-live-feedback">
              A két jelszónak meg kell egyezni!
            </b-form-invalid-feedback>
            <div v-if="fault" class="fault">Jelszó változtatás sikertelen!</div>
          </div>
        </div>
        <b-button @click="buttonClick" variant="primary" class="mt-4"
          >Elküld</b-button
        >
      </div>
      <div v-if="done">
      <p>
      Sikeres jelszó változtatás!
      </p>
    </div>
    </div>
  </div>
</template>

<script>
import { specialChar } from "../util/validation";
import Api from "../util/api";

export default {
  name: "newpass",
  data() {
    return {
      oldPassword: "",
      password: "",
      password2: "",
      oldPasswordType: "password",
      passwordType: "password",
      password2Type: "password",
      fault: false,
      done: false,
    };
  },
  methods: {
    oldPasswordTypeShow(type) {
      if (type) {
        this.oldPasswordType = "text";
      } else {
        this.oldPasswordType = "password";
      }
    },
    passwordTypeShow(type) {
      if (type) {
        this.passwordType = "text";
      } else {
        this.passwordType = "password";
      }
    },
    password2TypeShow(type) {
      if (type) {
        this.password2Type = "text";
      } else {
        this.password2Type = "password";
      }
    },
    buttonClick() {
      if (
        (this.oldPasswordState || this.$route.params.id) &&
        this.passwordState &&
        this.password2State
      ) {
        let param = "";
        if (this.$route.params.id) {
          param = this.$route.params.id;
        }

        let self = this;
        self.$loading(true);
        Api.newpass(
          this.oldPassword,
          this.password,
          param,
          localStorage.getItem("token")
        )
          .then(function (response) {
            if (response.data == "ok") {
              self.done = true;
            } else {
              self.fault = true;
            }
            self.$loading(false);
          })
          .catch((err) => {
            console.log(err);
            self.$loading(false);
          });
      }
    },
  },
  watch: {
    oldPassword() {
      this.fault = false;
    },
    password() {
      this.fault = false;
    },
    password2() {
      this.fault = false;
    }
  },
  computed: {
    oldPasswordState() {
      if (this.oldPassword.length != 0) {
        if (specialChar(this.oldPassword) && this.oldPassword.length > 7) {
          return true;
        } else {
          return false;
        }
      } else {
        return null;
      }
    },
    passwordState() {
      if (this.password.length != 0) {
        if (specialChar(this.password) && this.password.length > 7) {
          return true;
        } else {
          return false;
        }
      } else {
        return null;
      }
    },
    password2State() {
      if (this.password2.length != 0) {
        if (this.password2 == this.password) {
          return true;
        } else {
          return false;
        }
      } else {
        return null;
      }
    },
  },
};
</script>

<style scoped>
p {
  text-align: justify;
  padding: 15px;
  margin-top: 25px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background: rgba(30, 130, 0, 0.15);
}
.flexi {
  display: flex;
  flex-wrap: wrap;
}
.password-input {
  width: 90%;
}
.eye {
  line-height: 2.4rem;
  margin-left: 10px;
  cursor: pointer;
}
#input-live-feedback {
  width: 100%;
}
.logo-horizontal {
  width: 260px;
  margin: 0 auto;
  margin-top: 20px;
}
.container {
  position: relative;
  max-width: 700px;
  margin: 10px auto;
  padding: 35px 35px 60px 35px;
  color: #444;
  background: white;
}
h1 {
  text-align: center;
}
h2 {
  margin-top: 30px;
  color: #777;
}
h3 {
  text-align: center;
  margin-top: 50px;
}
h4 {
  text-align: center;
}
.fault {
  margin-top: 20px;
  color: red;
}
</style>